import { LanguageDeclaration } from './language';

export class DuplicateLanguageDeclarationException extends Error {}
export class UndefinedLanguageKeyException extends Error {
  constructor(message: string, public key: string) {
    super(message);
  }
}
export class NonListableLanguageKeyException extends Error {
  constructor(message: string, public key: string) {
    super(message);
  }
}
export interface LanguageItem {
  id: string;
  label: string;
}

export class LanguageRegistry {
  private static _map: LanguageDeclaration = {};
  private static _cache: Record<string, string | LanguageDeclaration> = {};
  private static _listCache: Record<string, LanguageItem[]> = {};

  static map(): Readonly<LanguageDeclaration> {
    return this._map;
  }

  static merge(lang: LanguageDeclaration) {
    this.recursiveMerge(this._map, lang);
  }

  static get(
    keys: string | string[],
  ): Readonly<LanguageDeclaration> | Readonly<string> | undefined {
    const key = this.coerceKey(keys);

    if (this._cache[key] !== undefined) return this._cache[key];

    const value = key.split('.').reduce((map: any, key: string) => {
      if (map === undefined) return undefined;

      if (typeof map === 'object') {
        return map[key];
      }

      return undefined;
    }, LanguageRegistry._map);

    if (value !== undefined) {
      this._cache[key] = value;
    }

    return value;
  }

  /**
   * @throws UndefinedLanguageKeyException If the key is not defined or not an egde node
   */
  static str(keys: string | string[]): string {
    const key = this.coerceKey(keys);
    const value = this.get(key);

    if (value === undefined) {
      throw new UndefinedLanguageKeyException(
        `LanguageRegistry: Key '${key}' is not defined.`,
        key,
      );
    }

    if (typeof value !== 'string') {
      throw new UndefinedLanguageKeyException(
        `LanguageRegistry: Key '${key}' is not an edge node, found ${typeof value}.`,
        key,
      );
    }

    return value;
  }

  /**
   * @throws UndefinedLanguageKeyException If the key is not defined or not an object node
   */
  static obj(keys: string | string[]): LanguageDeclaration {
    const key = this.coerceKey(keys);
    const obj = this.get(key);

    if (obj === undefined) {
      throw new UndefinedLanguageKeyException(
        `LanguageRegistry: Key '${key}' is not defined.`,
        key,
      );
    }

    if (typeof obj !== 'object') {
      throw new UndefinedLanguageKeyException(
        `LanguageRegistry: Key '${key}' is not an object node, found ${typeof obj}.`,
        key,
      );
    }

    return obj;
  }

  /**
   * @throws UndefinedLanguageKeyException If the key is not defined or not an object node
   * @throws NonListableLanguageKeyException If the object contains non-string values
   */
  static list(keys: string | string[]): LanguageItem[] {
    const key = this.coerceKey(keys);

    if (this._listCache[key] !== undefined) {
      return this._listCache[key];
    }

    const obj = this.obj(key);

    const list = Object.keys(obj).map((id) => {
      const label = obj[id];

      if (typeof label !== 'string') {
        throw new NonListableLanguageKeyException(
          `LanguageRegistry: Key '${id}' of '${key}' is not a string and cannot be used to make a list.`,
          key,
        );
      }

      return { id, label };
    });

    this._listCache[key] = list;

    return list;
  }

  static coerceKey(key: string | string[]): string {
    return Array.isArray(key) ? key.join('.') : key;
  }

  private static recursiveMerge(
    dest: LanguageDeclaration,
    source: LanguageDeclaration,
    prefix: string | null = null,
  ): LanguageDeclaration {
    for (const key in source) {
      if (dest[key] === undefined) {
        dest[key] = source[key];
      } else if (typeof source[key] === 'object' && typeof dest[key] === 'object') {
        dest[key] = this.recursiveMerge(
          <LanguageDeclaration>dest[key],
          <LanguageDeclaration>source[key],
          prefix ? `${prefix}.${key}` : key,
        );
      } else {
        throw new DuplicateLanguageDeclarationException(
          `LanguageRegistry: Key '${
            prefix ? prefix + '.' : ''
          }${key}' already registered, duplicate key ignored. ` +
            `Language declarations should contain unique keys.`,
        );
      }
    }

    return dest;
  }
}
