import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'x-pill-group',
  templateUrl: 'pill-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'x-pill-group',
  },
})
export class PillGroupComponent {}
