import { Component, Input } from '@angular/core';
import { TranslatedError } from '@x/common/error';

@Component({
  selector: 'x-dashboard-errors',
  templateUrl: './dashboard-errors.component.html',
  styleUrls: ['./dashboard-errors.component.html'],
  host: {
    class: 'x-dashboard-errors',
  },
})
export class DashboardErrorsComponent {
  @Input() errors: Array<TranslatedError>;

  showErrorDetail = false;

  toggleErrorDetail() {
    this.showErrorDetail = !this.showErrorDetail;
  }
}
