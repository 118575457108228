import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export type LocaleMap = { [key: string]: string };
export interface LocaleItem {
  id: string;
  name: string;
}

@Injectable()
export class LocaleService {
  private locales$: Observable<LocaleMap>;

  constructor(private http: HttpClient) {
    this.locales$ = http.get<LocaleMap>('/mediabucket/assets/locales.json').pipe(shareReplay());
  }

  list(): Observable<LocaleItem[]> {
    return this.locales$.pipe(map((map) => this.mapToList(map)));
  }

  search(text: string): Observable<LocaleItem[]> {
    text = text.toLowerCase();

    return this.list().pipe(
      map((list) => {
        return list.filter((item) => {
          return item.id.toLowerCase().includes(text) || item.name.toLowerCase().includes(text);
        });
      }),
    );
  }

  label(locale: string): Observable<string> {
    return this.locales$.pipe(
      map((map) => {
        return map[locale];
      }),
    );
  }

  private mapToList(map: LocaleMap): LocaleItem[] {
    return Object.keys(map).map((id) => {
      return { id, name: map[id] };
    });
  }
}
