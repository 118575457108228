import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'x-dashboard-layout-simplex',
  styleUrls: ['./dashboard-layout-simplex.component.scss'],
  templateUrl: 'dashboard-layout-simplex.component.html',
  host: {
    class: 'x-dashboard-layout-simplex',
  },
})
export class DashboardLayoutSimplexComponent {
  @Input()
  icon: IconDefinition | null = null;

  @Input()
  header: string | null = null;

  @Input()
  subheader: string | null = null;
}
