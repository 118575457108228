import { Clipboard } from '@angular/cdk/clipboard';
import { Directive, HostListener, input, signal } from '@angular/core';
import { WindowRef } from '@x/common/browser';

@Directive({
  selector: '[xClip]',
  host: {
    class: 'x-clip',
    '[class.copied]': 'copied()',
  },
})
export class ClipDirective {
  copy = input('', { transform: (v) => `${v ?? ''}`, alias: 'xClip' });

  copied = signal(false);

  constructor(
    private clipboard: Clipboard,
    private windowRef: WindowRef,
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.windowRef.getCurrentSelection()) {
      event.stopPropagation(); // Prevents click event from bubbling
      this.clipboard.copy(this.copy()); // Copy the value to clipboard
      this.copied.set(true);
      setTimeout(() => {
        this.copied.set(false);
      }, 800);
    }
  }
}
