<h2 mat-dialog-title>Error ({{ error.code }}) - {{ error.title }}</h2>
<div mat-dialog-content>
  <h3>{{ error.message }}</h3>
  <div>
    <x-json-list [data]="error.detail" />
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button type="button" (click)="closeDialog()">Close</button>
</div>
