import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Operation } from '@x/common/operation';
import { Observable } from 'rxjs';

export interface OperationSnackbarData {
  operation$: Observable<Operation>;
}

@Component({
  selector: 'x-operation-snackbar',
  templateUrl: 'operation-snackbar.component.html',
})
export class OperationSnackbarComponent {
  constructor(
    private ref: MatSnackBarRef<OperationSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: OperationSnackbarData,
  ) {}
}
