import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'xd-expand-item',
  templateUrl: 'expand-item.component.html',
  styleUrls: ['expand-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'xd-expand-item',
  },
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          visibility: 'hidden',
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
          visibility: 'visible',
        }),
      ),
      transition('collapsed <=> expanded', [animate('150ms ease')]),
    ]),
  ],
})
export class ExpandItemComponent {
  @HostBinding('class.open')
  @Input()
  isExpanded = false;

  @Input()
  icon?: IconProp | null;

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
