import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limit',
})
export class LimitPipe implements PipeTransform {
  transform<T = any>(value: T[] | null | undefined, limit = 10): [T[], T[]] {
    if (Array.isArray(value)) {
      if (value.length <= limit) {
        return [value, []];
      }

      const body = value.slice(0, limit);
      const tail = value.slice(limit);
      return [body, tail];
    }

    return [[], []];
  }
}
