import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  AnyOperationState,
  isErrorState,
  isFinalState,
  Operation,
  OperationClass,
} from './operation-state';

const HISTORY_LIMIT = 200;

export interface OperationHistoryItem {
  class: OperationClass;
  operation: Operation;
  state: AnyOperationState<any>;
  acked: boolean;
}

@Injectable({ providedIn: 'root' })
export class OperationHistoryService {
  private readonly _statusChanges$ = new Subject<void>();
  private readonly operations = new Array<Operation>();

  get loadingCount() {
    return this._loadingCount;
  }
  get errorCount() {
    return this._errorCount;
  }

  _loadingCount = 0;
  _errorCount = 0;

  constructor() {}

  hasErrors() {
    for (const op of this.operations) {
      if (op && isErrorState(op)) return true;
    }
    return false;
  }

  getErrorCount(ignoreAcked = true) {
    let count = 0;
    this.operations.forEach((operation) => {
      if (operation && isErrorState(operation) && (!ignoreAcked || !operation.isAcked)) {
        count++;
      }
    });
    return count;
  }

  getLoadingCount() {
    let count = 0;
    this.operations.forEach((operation) => {
      if (operation && !isFinalState(operation)) count++;
    });
    return count;
  }

  getHistory(): Operation[] {
    return this.operations.filter((op): op is Operation => !!op);
  }

  update(operation: Operation) {
    const index = this.operations.findIndex((op) => op?.id === operation.id);

    if (index === -1) {
      this.operations.push(operation);
    } else {
      this.operations[index] = operation;
    }
    this._errorCount = this.getErrorCount();
    this._loadingCount = this.getLoadingCount();
    this._statusChanges$.next();
  }

  ack(operation: Operation) {
    operation.ack();
    this._statusChanges$.next();
  }

  ackAll() {
    for (const operation of this.operations) {
      if (operation) operation.ack();
    }
    this._statusChanges$.next();
  }

  statusChanges() {
    return this._statusChanges$.asObservable();
  }
}
