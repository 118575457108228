import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from './locale.service';

@Pipe({
  name: 'localeLabel',
  pure: false,
})
export class LocaleLabelPipe implements PipeTransform {
  private cachedLocale: string | null = null;
  private cachedLabel: string = '';

  constructor(private localeService: LocaleService) {}

  transform(locale: string) {
    if (this.cachedLocale === locale) return this.cachedLabel;

    this.cachedLabel = locale;

    this.localeService.label(locale).subscribe((label) => {
      this.cachedLocale = locale;
      this.cachedLabel = label;
    });

    return this.cachedLabel;
  }
}
