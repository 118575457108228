import { Directive, Host, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { TranslatedError } from '@x/common/error';
import { Subscription } from 'rxjs';
import { OperationErrorState } from '../operation-state';
import {
  OperationDirective,
  OperationDirectiveContext,
  OperationStatusView,
} from './operation.directive';

type OperationErrorDirectiveContext = OperationDirectiveContext<
  undefined,
  TranslatedError,
  OperationErrorState
>;

@Directive({
  selector: '[xOperationError]',
})
export class OperationErrorDirective implements OnInit, OnDestroy {
  private _switch = new OperationStatusView<undefined, TranslatedError>(
    this.viewContainer,
    this.template,
  );
  private _sub: Subscription;

  constructor(
    public template: TemplateRef<OperationErrorDirectiveContext>,
    public viewContainer: ViewContainerRef,
    @Host()
    private operation: OperationDirective<any>,
  ) {}

  ngOnInit(): void {
    this._sub = this.operation.state$.subscribe((state) => {
      this._switch.switchForStatus(
        state,
        ['ok', 'loading', 'uninitialized'],
        false,
        state.error as any,
      );
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    this._switch.destroy();
  }

  /**
   * https://angular.io/guide/structural-directives#typing-the-directives-context
   */
  static ngTemplateContextGuard(
    dir: OperationErrorDirective,
    ctx: unknown,
  ): ctx is OperationErrorDirectiveContext {
    return true;
  }
}
