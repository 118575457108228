<div class="panel-error" *ngFor="let error of errors">
  <fa-icon
    icon="exclamation-circle"
    class="panel-error-icon"
    (click)="toggleErrorDetail()"
  ></fa-icon>
  <div class="panel-error-content">
    <h2 class="panel-error-title">{{ error.title }}</h2>
    <div class="panel-error-message">{{ error.message }}</div>
    <div *ngIf="showErrorDetail" class="panel-error-code">Error code: {{ error.code }}</div>
    <div *ngIf="error.detail && showErrorDetail" class="panel-error-detail">
      {{ error.detail }}
    </div>
  </div>
</div>
