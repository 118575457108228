// https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
const PROTOCOL_REGEX = new RegExp('^(?:[a-z]+:)?//', 'i');

export function isAbsolute(url: string) {
  return PROTOCOL_REGEX.test(url);
}

export function isAlternativeLink(url: string) {
  return url.indexOf('mailto:') === 0 || url.indexOf('tel:') === 0;
}

export function isRelative(url: string) {
  return !isAbsolute(url);
}

export function isFragment(url: string) {
  return url.indexOf('#') === 0;
}

export function hasFragment(url: string) {
  return url.includes('#');
}

export function normalizeRelative(url: string) {
  return url[0] === '/' ? url : '/' + url;
}

/**
 * https://stackoverflow.com/questions/6997262/how-to-pull-url-file-extension-out-of-url-string-using-javascript
 */
export function getUrlExtension(url: string): string | undefined {
  return url.split(/[#?]/, 1)[0].split('.').pop()?.trim();
}
