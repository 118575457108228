import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'x-action-row',
  templateUrl: 'action-row.component.html',
  styleUrls: ['action-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-action-row',
  },
})
export class ActionRowComponent {}
