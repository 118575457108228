import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDisplayDialogComponent } from './components/error-display-dialog/error-display-dialog.component';
import { TranslatedError } from './error-translation';

export interface ErrorReporter {
  report(error: any): void;
}

@Injectable()
export class ErrorReporterService {
  constructor(private dialogs: MatDialog) {}

  report(error: any) {
    console.error('ErrorReporterService: reporting error', error);
  }

  openErrorDialog(error: TranslatedError) {
    this.dialogs.open(ErrorDisplayDialogComponent, { data: error });
  }
}
