import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'x-pill',
  templateUrl: 'pill.component.html',
  host: {
    class: 'x-pill',
  },
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillComponent {
  @Input()
  set color(color: string) {
    this.colorClass = `color-${color}`;
  }

  @Input()
  text = '';

  @Input()
  icons: IconProp[] = [];

  @Input()
  set removable(val: any) {
    this._removable = coerceBooleanProperty(val);
  }

  _removable = false;

  @Output()
  removed = new EventEmitter();

  @Input()
  set icon(icon: IconProp | null) {
    this.icons = icon ? [icon] : [];
  }

  @HostBinding('class')
  colorClass = 'color-default';

  remove(event: Event) {
    event.stopPropagation();
    this.removed.emit(event);
  }
}
