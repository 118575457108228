import {
  Directive,
  Host,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AnyOperationState, OperationStatus } from '../operation-state';
import {
  OperationDirective,
  OperationDirectiveContext,
  OperationStatusView,
} from './operation.directive';

@Directive({
  selector: '[xOperationStatus]',
})
export class OperationStatusDirective<D = any> implements OnInit, OnDestroy {
  @Input('xOperationStatus')
  status: OperationStatus | OperationStatus[];

  private _switch = new OperationStatusView<D, AnyOperationState<D>>(
    this.viewContainer,
    this.template,
  );
  private _sub: Subscription;

  constructor(
    public template: TemplateRef<OperationDirectiveContext<D>>,
    public viewContainer: ViewContainerRef,
    @Host()
    private operation: OperationDirective<D>,
  ) {}

  ngOnInit(): void {
    this._sub = this.operation.state$.subscribe((state) => {
      this._switch.switchForStatus(state, this.status, true, state as any);
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    this._switch.destroy();
  }

  /**
   * https://angular.io/guide/structural-directives#typing-the-directives-context
   */
  static ngTemplateContextGuard(
    dir: OperationStatusDirective,
    ctx: unknown,
  ): ctx is OperationDirectiveContext<any> {
    return true;
  }
}
