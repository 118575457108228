import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ErrorModule } from '@x/common/error';
import { OperationErrorDirective } from './directives/operation-error.directive';
import { OperationLoadingDirective } from './directives/operation-loading.directive';
import { OperationResultDirective } from './directives/operation-result.directive';
import { OperationStatusDirective } from './directives/operation-status.directive';
import { OperationDirective } from './directives/operation.directive';
import { OperationHistoryService } from './operation-history.service';
import { OperationObserverService } from './operation-observer.service';

@NgModule({
  imports: [CommonModule, ErrorModule],
  declarations: [
    OperationDirective,
    OperationStatusDirective,
    OperationResultDirective,
    OperationErrorDirective,
    OperationLoadingDirective,
  ],
  exports: [
    OperationDirective,
    OperationStatusDirective,
    OperationResultDirective,
    OperationErrorDirective,
    OperationLoadingDirective,
  ],
})
export class OperationModule {
  static forRoot(): ModuleWithProviders<OperationModule> {
    return {
      ngModule: OperationModule,
      providers: [OperationObserverService, OperationHistoryService],
    };
  }
}
