import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'xd-card',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'xd-card',
  },
})
export class CardComponent {
  @Input()
  appearance: 'raised' | 'outlined' | 'none' = 'outlined';

  @HostBinding('class.raised')
  private get isRaised() {
    return this.appearance === 'raised';
  }

  @HostBinding('class.outlined')
  private get isOutlined() {
    return this.appearance === 'outlined';
  }

  @HostBinding('class.none')
  private get isNone() {
    return this.appearance === 'none';
  }
}
