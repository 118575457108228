import { Injectable } from '@angular/core';
import {
  LanguageItem,
  LanguageRegistry,
  NonListableLanguageKeyException,
  UndefinedLanguageKeyException,
} from './language-registry';

interface ILanguageService {
  str(keys: string | string[], fallback?: string): string;
  list(keys: string | string[], fallback?: LanguageItem[]): LanguageItem[];
}

@Injectable({ providedIn: 'root' })
export class LanguageService implements ILanguageService {
  str(keys: string | string[], fallback?: string): string {
    try {
      return LanguageRegistry.str(keys);
    } catch (e) {
      if (e instanceof UndefinedLanguageKeyException) {
        console.warn(e);
        return fallback ?? e.key;
      }
      throw e;
    }
  }

  list(keys: string | string[], fallback?: LanguageItem[]): LanguageItem[] {
    try {
      return LanguageRegistry.list(keys);
    } catch (e) {
      if (
        e instanceof UndefinedLanguageKeyException ||
        e instanceof NonListableLanguageKeyException
      ) {
        console.warn(e);
        return fallback ?? [];
      }

      throw e;
    }
  }
}
