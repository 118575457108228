import { Directive, Host, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OperationLoadingState } from '../operation-state';
import {
  OperationDirective,
  OperationDirectiveContext,
  OperationStatusView,
} from './operation.directive';

type OperationLoadingDirectiveContext = OperationDirectiveContext<
  undefined,
  number,
  OperationLoadingState
>;

@Directive({
  selector: '[xOperationLoading]',
})
export class OperationLoadingDirective implements OnInit, OnDestroy {
  private _switch = new OperationStatusView<undefined, number | undefined>(
    this.viewContainer,
    this.template,
  );
  private _sub: Subscription;

  constructor(
    public template: TemplateRef<OperationLoadingDirectiveContext>,
    public viewContainer: ViewContainerRef,
    @Host()
    private operation: OperationDirective<any>,
  ) {}

  ngOnInit(): void {
    this._sub = this.operation.state$.subscribe((state) => {
      this._switch.switchForStatus(state, ['loading'], true, state.loadingTime);
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    this._switch.destroy();
  }

  /**
   * https://angular.io/guide/structural-directives#typing-the-directives-context
   */
  static ngTemplateContextGuard(
    dir: OperationLoadingDirective,
    ctx: unknown,
  ): ctx is OperationLoadingDirectiveContext {
    return true;
  }
}
