import { Time } from '@angular/common';
import { DateTime, DateTimeOptions } from 'luxon';

export function coerceDateTime(value: any, options?: DateTimeOptions): DateTime | null {
  if (DateTime.isDateTime(value)) {
    if (options?.zone) return value.setZone(options.zone);
    return value;
  }
  if (typeof value === 'string') {
    return DateTime.fromISO(value, options);
  }
  if (value instanceof Date) {
    return DateTime.fromJSDate(value, options);
  }
  if (typeof value === 'number') {
    return DateTime.fromMillis(value, options);
  }
  return null;
}

export function coerceTimeValue(value: any): Time | null {
  if (!value) return null;
  if (typeof value === 'object' && 'hours' in value && 'minutes' in value) return value;
  if (typeof value !== 'string') return null;
  if (value.indexOf(':') < 0) return null;
  const [hours, minutes] = value.split(':').map((a) => Number(a));
  return {
    hours,
    minutes,
  };
}
