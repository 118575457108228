import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(
    value: Array<string | number | undefined | null> | null | undefined,
    separator = ', ',
  ): string | null | undefined {
    if (Array.isArray(value)) {
      return value.filter((s) => !!s).join(separator);
    }

    return value;
  }
}
