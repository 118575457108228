import { ErrorCode, TranslatedError } from '@x/common/error';
import { BehaviorSubject, Observable } from 'rxjs';
import { makeId } from '../utils';

export type OperationErrorStatus = ErrorCode | string;
export type OperationClass = 'ok' | 'loading' | 'cancelled' | 'error' | 'uninitialized';
export type OperationStatus = OperationClass | OperationErrorStatus;

const NON_ERROR_STATES = ['ok', 'loading', 'cancelled', 'uninitialized'];

const OperationStateClass = Symbol('OperationStateClass');

export interface IOperationActionOptions<
  T = any,
  S extends AnyOperationState<T> = AnyOperationState<T>,
> {
  label: string;
  do: (state: S) => Observable<any> | void;
}

export interface IOperationOptions<T = any> {
  label?: string;
  successLabel?: string | ((state: OperationSuccessState<T>) => string);
  errorLabel?: string | ((state: OperationErrorState) => string);
  errorActions?: IOperationActionOptions[];
  resultActions?: IOperationActionOptions[];
}

export class Operation<T = any, S extends AnyOperationState<T> = AnyOperationState<T>>
  implements IOperationState<T>
{
  static create<T = any>(options?: IOperationOptions) {
    return new Operation<T>(makeId(32), new OperationInitialState(), options);
  }

  static createBehaviourSubject<T = any>(options?: IOperationOptions) {
    return new BehaviorSubject<Operation<T>>(this.create());
  }

  get [OperationStateClass](): OperationClass {
    return this._state[OperationStateClass];
  }

  private _state: AnyOperationState<T>;

  get status(): S['status'] {
    return this._state.status;
  }

  get data(): S['data'] {
    return this._state.data;
  }

  get error(): S['error'] {
    return this._state.error;
  }

  get loadingTime(): S['loadingTime'] {
    return this._state.loadingTime;
  }

  get state(): S {
    return this._state as S;
  }

  get label() {
    return this.options?.label;
  }

  get stateClass(): OperationClass {
    return this._state[OperationStateClass];
  }

  get isAcked(): boolean {
    return this._ack;
  }

  private _ack = false;

  private constructor(public readonly id: string, state: S, public options?: IOperationOptions) {
    this._state = state;
  }

  nextState<M extends AnyOperationState<T> = AnyOperationState<T>>(state: M) {
    return new Operation<T, M>(this.id, state, this.options);
  }

  isErrorState(): this is Operation<T, OperationErrorState> {
    return isErrorState(this._state);
  }

  isSuccessState(): this is Operation<T, OperationSuccessState<T>> {
    return isSuccessState(this._state);
  }

  isFinalState(): this is Operation<T, OperationFinalState<T>> {
    return isFinalState(this._state);
  }

  isLoadingState(): this is Operation<T, OperationLoadingState> {
    return isLoadingState(this._state);
  }

  ack() {
    this._ack = true;
  }
}

export interface IOperationState<T = any> {
  [OperationStateClass]: OperationClass;
  status: OperationStatus;
  data?: T;
  error?: TranslatedError;
  loadingTime?: number;
}

export class OperationSuccessState<T = any> implements IOperationState<T> {
  readonly [OperationStateClass] = 'ok' as OperationClass;
  status: 'ok' = 'ok';
  error?: undefined;

  constructor(public data: T, public loadingTime?: number) {}

  static [Symbol.hasInstance](obj: any) {
    return typeof obj === 'object' && obj[OperationStateClass] === 'ok';
  }
}

export class OperationCancelledState<T = any> implements IOperationState<T> {
  readonly [OperationStateClass] = 'cancelled' as OperationClass;
  constructor(public data?: T, public loadingTime?: number) {}
  status: 'cancelled' = 'cancelled';
  error?: undefined;

  static [Symbol.hasInstance](obj: any) {
    return typeof obj === 'object' && obj[OperationStateClass] === 'cancelled';
  }
}

export class OperationErrorState implements IOperationState<undefined> {
  readonly [OperationStateClass] = 'error' as OperationClass;
  constructor(
    public status: OperationErrorStatus,
    public error: TranslatedError,
    public loadingTime?: number,
  ) {}
  data?: undefined;
  static [Symbol.hasInstance](obj: any) {
    return typeof obj === 'object' && obj[OperationStateClass] === 'error';
  }
}

export class OperationInitialState implements IOperationState<never> {
  readonly [OperationStateClass] = 'uninitialized' as OperationClass;
  status = 'uninitialized';
  data?: undefined;
  error?: undefined;
  loadingTime = 0;
  static [Symbol.hasInstance](obj: any) {
    return typeof obj === 'object' && obj[OperationStateClass] === 'uninitialized';
  }
}

export class OperationLoadingState<T = any> implements IOperationState<T> {
  readonly [OperationStateClass] = 'loading' as OperationClass;
  constructor(public data?: T, public loadingTime?: number) {}
  status: 'loading' = 'loading';
  error?: undefined;
  static [Symbol.hasInstance](obj: any) {
    return typeof obj === 'object' && obj[OperationStateClass] === 'loading';
  }
}

export type AnyOperationState<T = any> = (
  | OperationSuccessState<T>
  | OperationErrorState
  | OperationInitialState
  | OperationLoadingState<T>
  | OperationCancelledState<T>
) & { [OperationStateClass]: OperationClass };

export type OperationFinalState<T = any> =
  | OperationSuccessState<T>
  | OperationErrorState
  | OperationCancelledState<T>;

export function isOperationState<T = any>(state: any): state is AnyOperationState<T> {
  return (
    typeof state === 'object' && state !== null && typeof state[OperationStateClass] === 'string'
  );
}

export function isSuccessState<D>(
  state: Operation<D>,
): state is Operation<D, OperationSuccessState<D>>;
export function isSuccessState<D>(state: AnyOperationState<D>): state is OperationSuccessState<D>;
export function isSuccessState<D>(state: AnyOperationState<D> | Operation<D>): boolean {
  return state[OperationStateClass] === 'ok';
}

export function isErrorState(state: Operation): state is Operation<any, OperationErrorState>;
export function isErrorState(state: AnyOperationState): state is OperationErrorState;
export function isErrorState(state: AnyOperationState | Operation): boolean {
  return state[OperationStateClass] === 'error';
}

export function isFinalState<D>(state: Operation<D>): state is Operation<D, OperationFinalState<D>>;
export function isFinalState<D>(state: AnyOperationState<D>): state is OperationFinalState<D>;
export function isFinalState<D>(state: AnyOperationState<D> | Operation<D>): boolean {
  return (
    state[OperationStateClass] === 'ok' ||
    state[OperationStateClass] === 'cancelled' ||
    state[OperationStateClass] === 'error'
  );
}

export function isLoadingState(state: Operation): state is Operation<any, OperationLoadingState>;
export function isLoadingState(state: AnyOperationState): state is OperationLoadingState;
export function isLoadingState(state: AnyOperationState | Operation): boolean {
  return state[OperationStateClass] === 'loading';
}

export function operationStateClass(state: AnyOperationState): OperationClass {
  return state[OperationStateClass];
}
