import { ErrorHandler, Injectable } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { InjectLogger, Logger } from '@x/common/log';

@Injectable({ providedIn: 'root' })
export class NavigationErrorHandler {
  @InjectLogger()
  log: Logger;

  constructor(private router: Router, private errorHandler: ErrorHandler) {}

  handleError(error: any): any {
    this.log.warn('Error occurred during navigation.', this.router.getCurrentNavigation());
    // this.errorHandler.handleError(error);

    // navigate to error page so we at least render something
    // this.router.navigate(['/error'], {
    //   skipLocationChange: true,
    //   queryParams: { error: error?.message ?? String(error) },
    // });

    // this.//////

    throw error;
  }

  handleNavigationError(event: NavigationError) {
    this.log.debug('Caught NavigationError router event', event);
    const error = event.error;

    if (event.url.includes('/error')) {
      // this would indicate that the /error route
      // we are trying to navigate to itself has an error
      this.log.error(
        'Found navigation error pointing to /error route' +
          ', stopping redirect to prevent circular error.',
        event,
      );
      return;
    }

    if (error instanceof Error && error.message?.includes('Cannot match any routes.')) {
      // route not found
      this.errorHandler.handleError(event);
    } else {
      // likely a resolver error
    }

    // navigate to error page so we at least render something
    // this.router.navigate(['/error'], {
    //   skipLocationChange: true,
    //   queryParams: { error: error?.message ?? String(error) },
    // });
  }
}
