import { Directive, Host, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OperationSuccessState } from '../operation-state';
import {
  OperationDirective,
  OperationDirectiveContext,
  OperationStatusView,
} from './operation.directive';

type OperationResultDirectiveContext<D = any> = OperationDirectiveContext<
  D,
  D,
  OperationSuccessState<D>
>;

@Directive({
  selector: '[xOperationResult]',
})
export class OperationResultDirective<D = any> implements OnInit, OnDestroy {
  private _switch = new OperationStatusView<D, D, OperationSuccessState<D>>(
    this.viewContainer,
    this.template,
  );
  private _sub: Subscription;

  constructor(
    public template: TemplateRef<OperationResultDirectiveContext<D>>,
    public viewContainer: ViewContainerRef,
    @Host()
    private operation: OperationDirective<D>,
  ) {}

  ngOnInit(): void {
    this._sub = this.operation.state$.subscribe((state) => {
      this._switch.switchForStatus(state, 'ok', true, state.data as any);
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    this._switch.destroy();
  }

  /**
   * https://angular.io/guide/structural-directives#typing-the-directives-context
   */
  static ngTemplateContextGuard(
    dir: OperationResultDirective,
    ctx: unknown,
  ): ctx is OperationResultDirectiveContext<any> {
    return true;
  }
}
