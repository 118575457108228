import { animate, style, transition, trigger } from '@angular/animations';

export const FADE_IN_OUT_ANIMATION = trigger('fadeInOutAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms ease-in', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms ease-out', style({ opacity: 0 }))]),
]);

export const SLIDE_IN_FROM_RIGHT_ANIMATION = trigger('slideInFromRightAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(100%)', flex: 0 }),
    animate('200ms ease-out', style({ transform: 'translateX(0%)', flex: '0 1 inherit' })),
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0%)', flex: '0 1 inherit' }),
    animate('200ms ease-in', style({ transform: 'translateX(100%)', flex: 0 })),
  ]),
]);
