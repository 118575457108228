<div class="operation-list">
  <ng-container *ngFor="let item of operations; trackBy: trackById" [ngSwitch]="item.stateClass">
    <div *ngSwitchCase="'ok'" class="operation-item" [class]="item.stateClass">
      <fa-icon icon="circle-check" class="operation-item-icon"></fa-icon>
      <div class="operation-item-title">{{ item.label ?? 'Operation' }}</div>
      <div class="operation-item-status">Succesfull</div>
    </div>
    <div *ngSwitchCase="'loading'" class="operation-item" [class]="item.stateClass">
      <fa-icon icon="circle-three-quarters" class="operation-item-icon"></fa-icon>
      <div class="operation-item-title">{{ item.label ?? 'Operation' }}</div>
      <div class="operation-item-status">Loading</div>
    </div>
    <div *ngSwitchCase="'unitialized'" class="operation-item" [class]="item.stateClass">
      <fa-icon icon="circle" class="operation-item-icon"></fa-icon>
      <div class="operation-item-title">{{ item.label ?? 'Operation' }}</div>
      <div class="operation-item-status">Unitialized</div>
    </div>
    <div *ngSwitchCase="'cancelled'" class="operation-item" [class]="item.stateClass">
      <fa-icon icon="circle-xmark" class="operation-item-icon"></fa-icon>
      <div class="operation-item-title">{{ item.label ?? 'Operation' }}</div>
      <div class="operation-item-status">Cancelled</div>
    </div>
    <div
      *ngSwitchCase="'error'"
      class="operation-item"
      [class]="item.stateClass"
      (click)="expandedModel.toggle(item.id)"
    >
      <ng-container *ngIf="item.isErrorState()">
        <fa-icon icon="circle-exclamation" class="operation-item-icon"></fa-icon>
        <div class="operation-item-title">{{ item.label ?? 'Operation' }}</div>
        <div class="operation-item-status">Error [{{ item.error.code }}]</div>
        <div class="operation-item-line">{{ item.error.title }} - {{ item.error.message }}</div>
      </ng-container>
    </div>
    <div *ngSwitchCase="'error'" class="operation-error-log">
      <ng-container *ngIf="expandedModel.isSelected(item.id) && item.isErrorState()">
        <div class="operation-error-log-code">Error code: {{ item.error.code }}</div>
        <div class="operation-error-log-detail">{{ item.error.detail }}</div>
      </ng-container>
    </div>
    <div *ngSwitchDefault class="operation-item" [class]="item.stateClass"></div>
  </ng-container>
</div>

<ng-template></ng-template>
