import { InjectionToken } from '@angular/core';
import { IconDefinition, IconPack, IconPrefix } from '@fortawesome/fontawesome-svg-core';

export const ICONS_INCLUDED = new InjectionToken<IconDefinition[]>('ICONS_INCLUDED');
export const ICON_PACKS_INCLUDED = new InjectionToken<IconDefinition[]>('ICON_PACKS_INCLUDED');
export const ICONS_DEFAULT_PREFIX = new InjectionToken<IconDefinition[]>('ICONS_DEFAULT_PREFIX');
export const ICON_MODULE_CONFIG = new InjectionToken<IconDefinition[]>('ICONS_DEFAULT_PREFIX');

export interface IconModuleConfig {
  defaultPrefix: IconPrefix;
  fallbackIcon?: IconDefinition;
  fixedWidth?: boolean;
  icons?: IconDefinition[];
  packs?: IconPack[];
}
