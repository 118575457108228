<ol *ngIf="isArray(parsed)">
  <li *ngFor="let item of parsed">
    <x-json-list [data]="item" />
  </li>
</ol>

<ul *ngIf="isObject(parsed)">
  <li *ngFor="let item of parsed | keyvalue" class="obj">
    {{ item.key }}:
    <x-json-list [data]="item.value" />
  </li>
</ul>

<div *ngIf="isLiteral(parsed)" class="literal">{{ parsed }}</div>
