import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Operation } from '@x/common/operation';
import { Observable } from 'rxjs';
import {
  OperationSnackbarComponent,
  OperationSnackbarData,
} from '../components/operation-snackbar/operation-snackbar.component';

@Injectable()
export class DashboardSnackbarService {
  constructor(private readonly matSnackbar: MatSnackBar) {}

  openOperation(operation$: Observable<Operation>) {
    const data: OperationSnackbarData = { operation$ };

    return this.matSnackbar.openFromComponent(OperationSnackbarComponent, { data });
  }
}
