export const DASHBOARD_LANGUAGE = {
  DashboardColor: {
    red: 'red',
    pink: 'pink',
    purple: 'purple',
    'deep-purple': 'deep-purple',
    indigo: 'indigo',
    blue: 'blue',
    'light-blue': 'light-blue',
    cyan: 'cyan',
    teal: 'teal',
    green: 'green',
    'light-green': 'light-green',
    lime: 'lime',
    yellow: 'yellow',
    amber: 'amber',
    orange: 'orange',
    'deep-orange': 'deep-orange',
    brown: 'brown',
    gray: 'gray',
    'blue-gray': 'blue-gray',
  },
};
