import { InjectionToken } from '@angular/core';

export const ERROR_TRANSLATOR = new InjectionToken<ErrorTranslator[]>('ErrorTranslator array');

export const DEFAULT_ERROR_TRANSLATOR = new InjectionToken<ErrorTranslator>(
  'ErrorTranslator default',
);

export const enum ErrorCode {
  /**
   * Operation not started
   */
  UNINITIALIZED = 'uninitialized',
  /**
   * Unknown error
   */
  ERROR = 'error',
  /**
   * Resource not found error (404)
   */
  NOT_FOUND = 'not_found',
  /**
   * Server error (5xx)
   */
  SERVER_ERROR = 'server_error',
  /**
   * Http error 0
   */
  NETWORK_ERROR = 'network_error',
  /**
   * Took too long
   */
  TIMEOUT = 'timeout',
  /**
   * Unser input error (400)
   */
  INVALID = 'invalid',
  /**
   * User request error (4xx)
   */
  BAD_REQUEST = 'bad_request',
  /**
   * 401 Unauthorized (RFC 7235)
   */
  UNAUTHENTICATED = 'unauthenticated',
  /**
   * 403 Forbidden
   */
  UNAUTHORIZED = 'unauthorized',
}

export interface TranslatedError {
  code: ErrorCode | string;
  title: string;
  message: string;
  detail?: string;
  error?: any;
}

export interface ErrorTranslator<E = any> {
  canTranslate(error: any): boolean;
  translateError(error: E): TranslatedError;
}

export const DEFAULT_TRANSLATED_ERROR: TranslatedError = {
  code: ErrorCode.ERROR,
  title: $localize`:@@Error.GenericError.Title:Oops! Something went wrong`,
  message: $localize`:@@Error.GenericError.Message:Please try again or contact us.`,
};
