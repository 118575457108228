import { ErrorCode, ErrorTranslator, TranslatedError } from '@x/common/error';

export class DefaultErrorTranslator implements ErrorTranslator {
  canTranslate(error: any): boolean {
    return true;
  }
  translateError(error: any): TranslatedError {
    let detail = 'No detail';

    try {
      detail = JSON.stringify(error);
    } catch (e) {
      detail = `Unable to JSON.stringify error detail for error: ${error}`;
    }

    return {
      code: ErrorCode.ERROR,
      title: $localize`:@@Error.DefaultError.Title:Oops! Something went wrong.`,
      message: $localize`:@@Error.DefaultError.Message:An unknown error has occured. Try refreshing the page.`,
      detail,
      error,
    };
  }
}
