<div class="panel-header">
  <ng-content select="[xPanelHeader]"></ng-content>
</div>
<div class="panel-content">
  <ng-content select="[xPanelTab]"></ng-content>
  <ng-content select="[xPanelContent]"></ng-content>
  <ng-content></ng-content>

  @if (panelLoading()) {
    <div class="panel-overlay panel-loader-overlay" [@fadeInOutAnimation]>
      <!-- <x-cube-spinner></x-cube-spinner> -->
      <mat-progress-bar
        [mode]="panelLoading() ? 'indeterminate' : 'determinate'"
        [value]="panelLoading() ? 0 : 100"
      ></mat-progress-bar>
    </div>
  } @else if (shouldDisplayError()) {
    <div class="panel-overlay panel-error-overlay" [@fadeInOutAnimation]>
      <div class="panel-scroll">
        <div class="panel-error" *ngFor="let error of translatedErrors()">
          <fa-icon icon="exclamation-circle" class="panel-error-icon"></fa-icon>
          <div class="panel-error-content">
            <h2 class="panel-error-title">{{ error.title }}</h2>
            <div class="panel-error-message">{{ error.message }}</div>
            <div class="panel-error-detail-toggle" (click)="toggleErrorDetail()">
              {{ showErrorDetail ? 'Hide Detail' : 'Show Detail' }}
            </div>
            @if (showErrorDetail) {
              <div class="panel-error-code">Error code: {{ error.code }}</div>
            }
            @if (error.detail && showErrorDetail) {
              <div class="panel-error-detail">
                {{ error.detail }}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  }
</div>
<div class="panel-footer">
  <ng-content select="[xPanelFooter]"></ng-content>
</div>
@if (closeable()) {
  <div class="panel-close-button-insert" (click)="toggleClose()" matRipple>
    <fa-icon icon="angle-left"></fa-icon>
  </div>
}
