import { InjectionToken } from '@angular/core';
import { LogContext, LogFn, Logger, LogLevel, LogTransport } from './logger';

export const LOGGER = new InjectionToken<Logger>('LOGGER');

const DEFAULT_TRANSPORT: LogTransport = { push: console.log };

class StaticLogger implements Logger, LogTransport {
  private configured = false;

  constructor(
    private level: LogLevel = LogLevel.DEBUG,
    private namespace: string = 'App',
    private transport: LogTransport = DEFAULT_TRANSPORT,
  ) {}

  configure(options: { namespace?: string; transport?: LogTransport; level?: LogLevel }) {
    if (this.configured) {
      this.transport.push(
        LogLevel.WARN,
        'Attempting to configure StaticLogger that has already been configured.',
        { namespace: 'StaticLogger' },
      );
    }

    this.configured = true;
    this.level = options.level ?? this.level;
    this.transport = options.transport ?? this.transport;
    this.namespace = options.namespace ?? this.namespace;

    this.debug(`Log configured with namespace ${options.namespace}`);
  }

  push(level: LogLevel, message: string, context: LogContext): void {
    this.transport.push(level, message, context);
  }

  log: LogFn = (message, ...args) => {
    if (this.level > LogLevel.TRACE) return;
    this.transport.push(LogLevel.TRACE, message, { namespace: this.namespace, args });
  };

  debug: LogFn = (message, ...args) => {
    if (this.level > LogLevel.DEBUG) return;
    this.transport.push(LogLevel.DEBUG, message, { namespace: this.namespace, args });
  };

  info: LogFn = (message, ...args) => {
    if (this.level > LogLevel.INFO) return;
    this.transport.push(LogLevel.INFO, message, { namespace: this.namespace, args });
  };

  warn: LogFn = (message, ...args) => {
    if (this.level > LogLevel.WARN) return;
    this.transport.push(LogLevel.WARN, message, { namespace: this.namespace, args });
  };

  error: LogFn = (message, ...args) => {
    this.transport.push(LogLevel.ERROR, message, { namespace: this.namespace, args });
  };

  createSubnamespace(namespace: string, level?: LogLevel) {
    return new StaticLogger(level ?? this.level, `${this.namespace}/${namespace}`, this);
  }
}

export const Log = new StaticLogger();
