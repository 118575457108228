import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from './language.service';

@Pipe({
  name: 'l',
})
export class LanguagePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(key: string | string[]): string {
    if (key === undefined || key === null) return key;
    return this.languageService.str(key);
  }
}
