import { ChangeDetectionStrategy, Component } from '@angular/core';

const CUBES = [
  [0, 1, 1, 0, 1, 0, 1, 0, 1],
  [0, 1, 0, 0, 1, 0, 1, 1, 1],
  [0, 1, 1, 0, 1, 0, 0, 1, 1],
  [1, 1, 0, 0, 1, 0, 1, 1, 0],
  [0, 1, 1, 0, 1, 0, 1, 1, 0],
  [0, 1, 0, 1, 1, 0, 1, 0, 1],
];

@Component({
  selector: 'x-cube-spinner',
  templateUrl: 'cube-spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'x-cube-spinner',
  },
})
export class CubeSpinnerComponent {
  cubes = CUBES[Math.round(Math.random() * CUBES.length)];
}
