import { Logger } from '../logger';
import { Log } from '../static-logger';

export function InjectLogger<U = Logger>(): PropertyDecorator {
  return (target: any, propertyKey: string | symbol): void => {
    const logger = Log.createSubnamespace(String(target.constructor?.name ?? target));
    Object.defineProperties(target, {
      [String(propertyKey)]: {
        configurable: false,
        get() {
          return logger;
        },
      },
    });
  };
}
