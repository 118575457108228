import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IOperationState, Operation, OperationStatus, isOperationState } from '../operation-state';

export type OperationDirectiveContext<D = any, I = any, S = IOperationState<D>> = S & {
  $implicit: I;
};

/**
 * See https://github.com/angular/angular/blob/13.3.1/packages/common/src/directives/ng_switch.ts
 */
export class OperationStatusView<
  D = any,
  I = any,
  S extends IOperationState<D> = IOperationState<D>,
> {
  private _embed: EmbeddedViewRef<OperationDirectiveContext<D, I, S>> | null = null;

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _templateRef: TemplateRef<OperationDirectiveContext<D, I, S>>,
  ) {}

  create(): void {
    this._embed = this._viewContainerRef.createEmbeddedView(this._templateRef);
  }

  destroy(): void {
    this._embed = null;
    this._viewContainerRef.clear();
  }

  switchForStatus(
    state: IOperationState<D>,
    status: OperationStatus | OperationStatus[],
    include = true,
    $implicit: I,
  ) {
    const matchStatus = Array.isArray(status)
      ? include
        ? status.includes(state.status)
        : !status.includes(state.status)
      : include
        ? status === state.status
        : status !== state.status;

    if (matchStatus && !this._embed) {
      this.create();
    } else if (!matchStatus && this._embed) {
      this.destroy();
    }

    if (matchStatus && this._embed) {
      // Update context properties individually
      const ctx = this._embed.context;
      ctx.status = state.status;
      ctx.data = state.data;
      ctx.error = state.error;
      ctx.loadingTime = state.loadingTime;
      ctx.$implicit = $implicit;

      // Manually trigger change detection for this view
      this._embed.detectChanges();
    }
  }
}

@Directive({
  selector: '[xOperation]',
})
export class OperationDirective<D = any> {
  state$ = new BehaviorSubject<IOperationState<D>>(Operation.create());

  @Input('xOperation')
  set state(state: IOperationState<D> | null | undefined) {
    if (isOperationState(state)) {
      this.state$.next(state);
    }
  }

  get status() {
    return this.state$.getValue().status;
  }

  getState() {
    return this.state$.getValue();
  }
}
