<fa-icon *ngIf="icon" class="toolbar-icon" [icon]="icon" size="2x"></fa-icon>
<h2 *ngIf="header" class="toolbar-header">{{ header }}</h2>
<div *ngIf="icon || header" class="toolbar-header-divider"></div>
<h3 *ngIf="subheader" class="toolbar-subheader">{{ subheader }}</h3>
<div *ngIf="contentPosition === 'end'" class="toolbar-header-spacer"></div>
<ng-content></ng-content>
<div *ngIf="contentPosition === 'start'" class="toolbar-header-spacer"></div>
<div class="toolbar-actions">
  <ng-content select="[xToolbarAction]"></ng-content>
</div>
