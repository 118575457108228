import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'x-error',
  template: 'Error occured: {{ error }}',
})
export class ErrorComponent implements OnInit {
  error: string | null = null;

  constructor(private route: ActivatedRoute, private location: Location) {}

  ngOnInit(): void {
    this.error = this.route.snapshot.queryParamMap.get('error');
    console.log('ErrorComponent: render error', this.error);
  }
}
