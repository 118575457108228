import { ErrorHandler } from '@angular/core';
import { delay, map, merge, Observable, Subject, Subscription, takeUntil, timer } from 'rxjs';
import { ErrorTranslatorService } from '../error';
import { OperationHistoryService } from './operation-history.service';
import { TimerEvent } from './operation-observer.service';
import {
  AnyOperationState,
  IOperationOptions,
  Operation,
  OperationCancelledState,
  OperationErrorState,
  OperationLoadingState,
  OperationSuccessState,
} from './operation-state';

export class ObservableOperation<T = any> extends Observable<Operation<T>> {
  operation: Operation<T>;

  constructor(
    readonly observable: Observable<T>,
    readonly history: OperationHistoryService,
    readonly errorTranslator: ErrorTranslatorService,
    readonly errorHandler: ErrorHandler,
    readonly options?: IOperationOptions,
  ) {
    super((subject) => {
      let lastResult: T | undefined = undefined;
      let lastResultIsSet = false;

      const startTime = new Date().getTime();
      const timeStop = new Subject<void>();
      const time = timer(0, 500).pipe(
        map((i) => new TimerEvent(new Date().getTime() - startTime)),
        takeUntil(timeStop),
      );

      let source: Subscription = new Subscription();

      const updateState = (state: AnyOperationState<T>) => {
        this.operation = this.operation.nextState(state);
        if (!subject.closed) {
          subject.next(this.operation);
        }
        history.update(this.operation);
      };

      source = merge(observable, time)
        .pipe(delay(0))
        .subscribe({
          next: (result) => {
            if (result instanceof TimerEvent) {
              updateState(new OperationLoadingState(lastResult, result.time));
            } else {
              lastResult = result;
              lastResultIsSet = true;
              timeStop.next();
              timeStop.complete();
            }
          },
          error: (error) => {
            const trans = errorTranslator.translateError(error);
            errorHandler.handleError(error);
            updateState(new OperationErrorState(trans.code, trans));
            subject.complete();
          },
          complete: () => {
            if (!lastResultIsSet) {
              console.warn(
                'OperationObserverService: operation completed without emitting a final result',
                observable,
                lastResult,
              );
            }

            if (lastResult === undefined || lastResult === null) {
              updateState(new OperationCancelledState(lastResult));
            } else {
              updateState(new OperationSuccessState(lastResult));
            }

            source.unsubscribe();
            subject.complete();
          },
        });

      updateState(new OperationLoadingState());

      return () => {
        if (!this.operation.isFinalState()) {
          updateState(new OperationCancelledState(lastResult));
        }
        source.unsubscribe();
        if (!timeStop.closed) {
          timeStop.next();
          timeStop.complete();
        }
      };
    });

    this.operation = Operation.create<T>(options);
  }
}
