/**
 * Wraps critical error details which occurred during render.
 *  - Allows server-side renderer to access error details and return correct status code.
 *  - Browser application renderer
 *
 */
export class ErrorResponseService {
  code = 200;

  constructor() {}

  setCode(code: number) {
    this.code = code;
  }

  setNotFound() {
    this.code = 404;
  }

  getCode() {
    return this.code;
  }
}
