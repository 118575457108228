<div class="list-item-icon" *ngIf="icon">
  <fa-icon [icon]="icon"></fa-icon>
</div>
<div class="list-item-body">
  <ng-content select="[xdHeadline]"></ng-content>
  <ng-content select="[xdLine]"></ng-content>
</div>
<div class="list-item-meta">
  <ng-content></ng-content>
</div>
