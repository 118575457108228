<div class="card">
  <div class="card-header">
    <ng-content select="[xdHeader]"></ng-content>
  </div>
  <div class="card-body">
    <ng-content select="[xdBody]"></ng-content>
  </div>
  <ng-content></ng-content>
  <ng-content select="[xdFooter]"></ng-content>
</div>
