import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'x-json-list',
  templateUrl: './json-list.component.html',
  styleUrl: './json-list.component.scss',
})
export class JsonListComponent implements OnInit {
  public parsed: Array<unknown> | Record<string, unknown> | string | null;
  @Input() data: unknown;

  ngOnInit() {
    let data;
    try {
      // `this.data` may be a JSON string, so try to parse and fallback to the unparsed string if it isn't JSON
      data = typeof this.data === 'string' ? JSON.parse(this.data) : this.data;
    } catch {
      data = this.data;
    }

    switch (true) {
      case Array.isArray(data):
        this.parsed = data;
        break;
      case data == null:
        this.parsed = null;
        break;
      case typeof data === 'object':
        this.parsed = Object.fromEntries(Object.entries(data).map(([k, v]) => [k.toString(), v]));
        break;
      default:
        this.parsed = data.toString();
        break;
    }
  }

  isArray(x: typeof this.parsed): x is Array<unknown> {
    return Array.isArray(x);
  }

  isObject(x: typeof this.parsed): x is Record<string, unknown> {
    return !Array.isArray(x) && x != null && typeof x === 'object';
  }

  isLiteral(x: typeof this.parsed): x is string | null {
    return x === null || typeof x === 'string';
  }
}
