import { coerceArray } from '@angular/cdk/coercion';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterList',
})
export class FilterListPipe<T = any> implements PipeTransform {
  transform(
    list: Array<T> | null | undefined,
    value: any,
    propertyNames?: keyof T | Array<keyof T>,
  ): Array<T> | null | undefined {
    console.log({ list, value, propertyNames });
    if (list === null || list === undefined) {
      return list;
    }

    if (value === null || value === undefined) {
      return list;
    }

    if (propertyNames !== undefined) {
      const c = list.filter((item) => {
        if (this.isObject(item)) {
          for (let propertyName of coerceArray(propertyNames)) {
            let itemValue: any;

            if (typeof propertyName === 'string' && propertyName.includes('.')) {
              itemValue = this.propertyByDotNotation(item, propertyName);
            } else {
              itemValue = item[propertyName];
            }

            if (this.compareValues(itemValue, value)) {
              return true;
            }
          }
          return false;
        }

        return this.compareValues(item, value);
      });
      console.log(c);
      return c;
    }

    return list.filter((item) => {
      return this.compareValues(item, value);
    });
  }

  private isObject(item: T): boolean {
    return item && typeof item === 'object';
  }

  private compareValues(itemValue: any, search: string): boolean {
    console.log(typeof itemValue);
    switch (typeof itemValue) {
      case 'string':
        return itemValue.toLowerCase().includes(search.toLowerCase());
      case 'number':
        return String(itemValue).toLowerCase().includes(search.toLowerCase());
      case 'boolean':
        return itemValue === Boolean(search);
      default:
        return itemValue === search;
    }
  }

  private propertyByDotNotation(obj: any, path: string) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
}
