import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatedError } from '../../error-translation';

@Component({
  selector: 'x-error-display-dialog',
  templateUrl: './error-display-dialog.component.html',
  styleUrls: ['./error-display-dialog.component.scss'],
})
export class ErrorDisplayDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly error: TranslatedError,
    private readonly dialog: MatDialogRef<ErrorDisplayDialogComponent>,
  ) {}

  closeDialog() {
    this.dialog.close();
  }
}
