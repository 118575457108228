import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[xColor]',
})
export class IconColorDirective {
  @Input('xColor')
  set color(color: 'primary' | 'warn' | 'accent' | 'current' | 'grey') {
    this.colorClass = 'icon-color-' + color;
  }

  @HostBinding('class')
  colorClass = 'icon-color-default';
}
