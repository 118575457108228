<div class="expand-item-toggle" (click)="toggle()">
  <div class="expand-item-toggle-icon">
    @if (icon) {
      <fa-icon [icon]="icon"></fa-icon>
    }
  </div>
  <div class="expand-item-toggle-body">
    <ng-content select="[xdHeadline]"></ng-content>
    <ng-content select="[xdLine]"></ng-content>
  </div>
  <div class="expand-item-toggle-description mat-typography">
    <ng-content select="[xdDescription]"></ng-content>
  </div>
  <div class="expand-item-toggle-chevron">
    <fa-icon icon="chevron-down"></fa-icon>
  </div>
</div>
<div class="expand-item-content" [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
  <ng-content select="xd-list-item,xd-expand-item,[xdBody]"></ng-content>
  <div class="expand-item-content-inner mat-typography">
    <ng-content></ng-content>
  </div>
</div>
