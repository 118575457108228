import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { ErrorDisplayDialogComponent } from './components/error-display-dialog/error-display-dialog.component';
import { JsonListComponent } from './components/json-list/json-list.component';
import { ErrorReporterService } from './error-reporter.service';
import { DEFAULT_ERROR_TRANSLATOR, ERROR_TRANSLATOR, ErrorTranslator } from './error-translation';
import { ErrorTranslatorService } from './error-translator.service';
import { ErrorComponent } from './error.component';
import { NavigationErrorHandler } from './navigation-error-handler';
import { RootErrorHandler } from './root-error-handler';
import { DefaultErrorTranslator } from './translators/default.error-translator';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'error',
        component: ErrorComponent,
      },
    ]),
    MatDialogModule,
    MatButtonModule,
  ],
  declarations: [ErrorComponent, ErrorDisplayDialogComponent, JsonListComponent],
  exports: [RouterModule, ErrorComponent],
  providers: [],
})
export class ErrorModule {
  static forRoot(): ModuleWithProviders<ErrorModule> {
    return {
      ngModule: ErrorModule,
      providers: [
        {
          provide: DEFAULT_ERROR_TRANSLATOR,
          useClass: DefaultErrorTranslator,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (navErrorHandler: NavigationErrorHandler, router: Router) => {
            return () => {
              router.errorHandler = (error: any) => navErrorHandler.handleError(error);
            };
          },
          deps: [NavigationErrorHandler, Router],
          multi: true,
        },
        {
          provide: ErrorHandler,
          useClass: RootErrorHandler,
        },
        ErrorTranslatorService,
        NavigationErrorHandler,
        ErrorReporterService,
      ],
    };
  }

  static forFeature(
    translationProviders: Type<ErrorTranslator>[],
  ): ModuleWithProviders<ErrorModule> {
    return {
      ngModule: ErrorModule,
      providers: [
        translationProviders.map((useClass) => ({
          provide: ERROR_TRANSLATOR,
          useClass,
          multi: true,
        })),
      ],
    };
  }
}
