import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Operation, OperationHistoryService } from '@x/common/operation';
import { debounceTime, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'x-dashboard-operation-history',
  templateUrl: 'dashboard-operation-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class DashboardOperationHistoryComponent implements OnInit, OnDestroy {
  errorCount: number = 0;
  loadingCount: number = 0;
  operations: Operation[] = [];

  expandedModel = new SelectionModel<string>();

  private readonly _destroy$ = new Subject<void>();

  trackById = (i: number, item: Operation) => item.id;

  constructor(
    private readonly operationHistory: OperationHistoryService,
    private readonly changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.operationHistory
      .statusChanges()
      .pipe(startWith(null), debounceTime(200), takeUntil(this._destroy$))
      .subscribe(() => {
        this.errorCount = this.operationHistory.getErrorCount();
        this.loadingCount = this.operationHistory.getLoadingCount();
        this.operations = this.operationHistory.getHistory().reverse();
        this.changeRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
