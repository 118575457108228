import { ModuleWithProviders, NgModule } from '@angular/core';
import { LanguageDeclaration } from './language';
import { LanguageListPipe } from './language-list.pipe';
import { LanguageRegistry } from './language-registry';
import { LanguagePipe } from './language.pipe';
import { LanguageService } from './language.service';
import { LocaleLabelPipe } from './locale-label.pipe';
import { LocaleService } from './locale.service';
import { TranslateFieldPipe } from './translate-field.pipe';
import { TranslateFilterPipe } from './translate-filter.pipe';

const PIPES = [
  LocaleLabelPipe,
  TranslateFieldPipe,
  LanguagePipe,
  LanguageListPipe,
  TranslateFilterPipe,
];

@NgModule({
  declarations: [PIPES],
  exports: [PIPES],
})
export class LocaleModule {
  public static forRoot(declarations?: LanguageDeclaration[]): ModuleWithProviders<LocaleModule> {
    if (Array.isArray(declarations)) {
      declarations.forEach((d) => LanguageRegistry.merge(d));
    }

    return {
      ngModule: LocaleModule,
      providers: [LocaleService, LanguageService],
    };
  }

  public static forChild(
    declarations: LanguageDeclaration[] | LanguageDeclaration,
  ): ModuleWithProviders<LocaleModule> {
    if (Array.isArray(declarations)) {
      declarations.forEach((d) => LanguageRegistry.merge(d));
    } else {
      LanguageRegistry.merge(declarations);
    }

    return {
      ngModule: LocaleModule,
      providers: [],
    };
  }
}
