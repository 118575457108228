import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition, IconPack } from '@fortawesome/fontawesome-svg-core';
import { IconColorDirective } from './icon-color.directive';
import {
  IconModuleConfig,
  ICONS_INCLUDED,
  ICON_MODULE_CONFIG,
  ICON_PACKS_INCLUDED,
} from './icon.config';

@NgModule({
  providers: [],
  declarations: [IconColorDirective],
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule, IconColorDirective],
})
export class IconModule {
  static forRoot(config: IconModuleConfig): ModuleWithProviders<IconModule> {
    return {
      ngModule: IconModule,
      providers: [
        {
          provide: ICON_MODULE_CONFIG,
          useValue: config,
        },
        {
          provide: ICONS_INCLUDED,
          useValue: config.icons ?? [],
          multi: true,
        },
        {
          provide: ICON_PACKS_INCLUDED,
          useValue: config.packs ?? [],
          multi: true,
        },
      ],
    };
  }

  static forFeature(icons: IconDefinition[]): ModuleWithProviders<IconModule> {
    return {
      ngModule: IconModule,
      providers: [
        {
          provide: ICONS_INCLUDED,
          useValue: icons,
          multi: true,
        },
      ],
    };
  }

  constructor(
    faConfig: FaConfig,
    library: FaIconLibrary,
    @Inject(ICON_MODULE_CONFIG) config: IconModuleConfig,
    @Inject(ICONS_INCLUDED) icons: IconDefinition[][],
    @Inject(ICON_PACKS_INCLUDED) packs: IconPack[][],
  ) {
    if (config.fallbackIcon) faConfig.fallbackIcon = config.fallbackIcon;
    faConfig.fixedWidth = config.fixedWidth ?? true;
    faConfig.defaultPrefix = config.defaultPrefix;
    library.addIcons(...icons.flat());
    library.addIconPacks(...packs.flat());
  }
}
