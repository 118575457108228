import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorTranslatorService } from '@x/common/error';
import { ObservableOperation } from '@x/common/operation/observable-operation';
import { OperationHistoryService } from '@x/common/operation/operation-history.service';
import { OperationQueue } from '@x/common/operation/operation-queue';
import {
  AnyOperationState,
  IOperationOptions,
  isFinalState,
  Operation,
  OperationFinalState,
  OperationInitialState,
} from '@x/common/operation/operation-state';
import { OperationSubject, OperationSubjectOptions } from '@x/common/operation/operation-subject';
import { lastValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export class TimerEvent {
  constructor(public time: number) {}
}

@Injectable({ providedIn: 'root' })
export class OperationObserverService {
  constructor(
    private history: OperationHistoryService,
    private errorTranslator: ErrorTranslatorService,
    private errorHandler: ErrorHandler,
  ) {}

  observe<T = any>(observable: Observable<T>, options?: IOperationOptions): ObservableOperation<T> {
    return new ObservableOperation<T>(
      observable,
      this.history,
      this.errorTranslator,
      this.errorHandler,
      options,
    );
  }

  observeState<T = any>(
    observable: Observable<T>,
    options?: IOperationOptions,
  ): Observable<AnyOperationState<T>> {
    return this.observe(observable, options).pipe(map((o) => o.state));
  }

  finalize<T = any>(observable: Observable<T>): Observable<Operation<T, OperationFinalState<T>>> {
    return this.observe(observable).pipe(
      filter((state): state is Operation<T, OperationFinalState<T>> => {
        return isFinalState(state);
      }),
    );
  }

  promise<T = any>(observable: Observable<T>): Promise<Operation<T, OperationFinalState<T>>> {
    return lastValueFrom(this.finalize(observable));
  }

  control<T = any, A = undefined>(
    operation: (args: A) => Observable<T>,
    options?: OperationSubjectOptions,
  ): OperationSubject<T, A> {
    return new OperationSubject(operation, this, options);
  }

  createQueue() {
    return new OperationQueue(this);
  }

  static createInitialState() {
    return new OperationInitialState();
  }
}
